.Overlay {
	background-color: rgb(0, 0, 0, 0.7);
	width: 500px;
	height: 400px;
	position: absolute;
	overflow: hidden;
	backdrop-filter: blur(10px);
	border-radius: 15px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	padding: 10px;
}

.Overlay::before {
	top: 0;
	left: 0;
	position: absolute;
	content: " ";
	width: 500px;
	height: 400px;
	z-index: -1;
	background-color: rgb(255, 255, 255, 0.07);
}

.Overlay .Head {
	position: relative;
	display: flex;
	justify-content: space-between;
	gap: 15px;
}

.Overlay .Head .Close {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.Overlay .Head .Close:hover {
	color: #ffcf25;
}

.Overlay .Head input {
	width: 100%;
	outline: none;
	border: none;
	background-color: #0000;
	font-size: 16px;
	color: #fff;
}

.Overlay .Buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 7.5px;

	text-align: center;
}

.Overlay .Buttons .Button {
	flex: 1 1 calc(33.333% - 10px);
	position: relative;
	background-color: #0000;
	outline: none;
	border: 0;
	border-radius: 12.5px;
	color: #fff;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Overlay .Buttons .Input {
    text-align: center;
	flex: 1 1 calc(33.333% - 10px);
	position: relative;
	background-color: #0000;
	outline: none;
	border: 0;
	border-radius: 12.5px;
	color: #fff;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Overlay .Buttons .Select {
    text-align: center;
	flex: 1 1 calc(33.333% - 10px);
	position: relative;
	background-color: #0000;
	outline: none;
	border: 0;
	border-radius: 12.5px;
	color: #fff;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Overlay .Buttons .Select option {
	width: 100%;
	background-color: #000;
	outline: none;
	border: none;
	font-size: 16px;
	color: #fff;
	padding: 15px 15px;
}

.Overlay .Buttons .Button:hover {
	color: #ffcf25;
	background-color: rgb(255, 255, 255, 0.05);
}

.Overlay .Ellipse1 {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 154px;
	border-radius: 330px;
	pointer-events: none;
	background: #ffd0252a;
	height: 154px;
	filter: blur(100px);
}

.Overlay .ScrollContent {
    width: 100%;
	margin-top: 10px;
	height: calc(100% - 30px);
	overflow-y: auto;
    overflow-x: hidden;
	touch-action: pan-y;
}

.Overlay .ScrollContent p {
    max-width: 100%;
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
	margin-bottom: 3px;
}

.Overlay .ScrollContent a {
    width: 100%;
    color: #ffcf25;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;

}


@media screen and (max-width: 850px) {
	.Overlay {
		width: 100%;
	}
}
