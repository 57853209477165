.DatePicker {
	position: relative;
	width: fit-content;
}

.DatePickerInput {
	cursor: pointer;
	width: fit-content;
	background-color: transparent;
	outline: none;
	border: none;
	font-size: 16px;
	color: #fff;
	text-align: left;
}

.DatePickerCalendar {
	overflow: hidden;
	backdrop-filter: blur(5px);
	position: absolute;
	top: 50px;
	left: 0;
	width: 400px;
	background-color: rgb(0, 0, 0, 0.7);
	color: #fff;
	border-radius: 15px;
	padding: 10px;
	z-index: 1000;
}

.DatePickerCalendar::before {
	top: 0;
	left: 0;
	position: absolute;
	content: " ";
	width: 100%;
	height: 100%;
	z-index: -1;
	border-radius: 15px;
	background-color: rgb(255, 255, 255, 0.07);
}

.DatePickerCalendar .Ellipse1 {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 154px;
	border-radius: 330px;
	pointer-events: none;
	background: #ffd0252a;
	height: 154px;
	filter: blur(100px);
}

.DatePickerHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.DatePickerGrid {
	display: grid;
	grid-template-columns: repeat(7, calc((400px - 50px) / 7));
	gap: 5px;
}

.DatePickerDay {
	padding: 10px;
	text-align: center;
	border-radius: 7.5px;
	transition: all 0.3s ease-in-out;
}

.DatePickerDay:hover {
	color: #ffcf25 !important;
	background-color: rgb(255, 255, 255, 0.05);
}

.DatePickerDay.Selected {
	background-color: rgb(255, 255, 255, 0.07);
}


@media screen and (max-width: 850px) {
	.DatePickerCalendar {
		width: 400px;
		left: -55px;
	}


}