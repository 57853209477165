.AdminLayout {
	width: 100%;
	max-width: 1200px;
	margin: 15px auto;
	padding: 0px 15px;
	height: calc(100% - 30px);
	overflow: auto !important;
}

.AdminLayout .Header {
	margin: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.AdminLayout .Users {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.AdminLayout .Users .Item {
	flex: 1 1 content;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
	padding: 15px;
	border-radius: 15px;
	background-color: rgb(255, 255, 255, 0.07);
}

.AdminLayout .Users .Item .MainInfo .Status {
	font-size: 12px;
}

.AdminLayout .Users .Item .Icons {
	display: flex;
	gap: 15px;
}

.AdminLayout svg {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.AdminLayout svg:hover {
	color: #ffcf25;
}

.AdminLayout .Manage {
	background-color: rgb(0, 0, 0, 0.7);
	width: 500px;
	height: fit-content;
	position: absolute;
	overflow: hidden;
	backdrop-filter: blur(10px);
	border-radius: 15px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	padding: 10px;
}

.AdminLayout .Manage::before {
	top: 0;
	left: 0;
	position: absolute;
	content: " ";
	width: 500px;
	height: 100%;
	z-index: -1;
	background-color: rgb(255, 255, 255, 0.07);
}

.AdminLayout .Manage .Close {
	position: absolute;
	right: 10px;
	top: 10px;
}

.AdminLayout .Manage .Ellipse1 {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 154px;
	border-radius: 330px;
	pointer-events: none;
	background: #ffd0252a;
	height: 154px;
	filter: blur(100px);
}

.AdminLayout .Manage input {
	width: 100%;
	background-color: #0000;
	outline: none;
	border: none;
	font-size: 16px;

	color: #fff;
	padding: 15px 15px;
}


.AdminLayout .Manage select {
    position: relative;
    appearance: none;
	-webkit-appearance: none;
	width: 100%;
	background-color: #0000;
	outline: none;
	border: none;
	font-size: 16px;
	color: #fff;
	padding: 15px 15px;
}

  
.AdminLayout .Manage option {
	width: 100%;
	background-color: #000;
	outline: none;
	border: none;
	font-size: 16px;
	color: #fff;
	padding: 15px 15px;
}

.AdminLayout .Manage input[type="number"] {
	width: 50%;
	text-align: center;
}

.AdminLayout .Manage button {
    margin-bottom: 5px;
    width: 100%;
	position: relative;
	background-color: #0000;
	outline: none;
	border: 0;
	border-radius: 12.5px;
	color: #fff;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.AdminLayout .Manage button:last-of-type {
    margin-bottom: 0px;
}

.AdminLayout .Manage button:hover {
	color: #ffcf25;
	background-color: rgb(255, 255, 255, 0.05);
}

.AdminLayout .Logs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: rgb(255, 255, 255, 0.07);
    padding: 15px;
    border-radius: 15px;
}

.AdminLayout .Logs .LogsItem {
    display: flex;
    justify-content: space-between;
}
.AdminLayout .Header .WithDate {
	display: flex;
	gap: 15px;
	align-items: baseline;
}

@media screen and (max-width: 850px) {
	.AdminLayout .Manage {
		width: 100vw;
	}

	.AdminLayout .Header .WithDate {
		flex-wrap: wrap;
		width: calc(100% - 32px);
	}

	.AdminLayout .Header .WithDate h1 {
		width: 100%;
	}
	.AdminLayout .Header .WithDate p {
		width: 30px;
	}
	.AdminLayout .Header .WithDate .DatePicker {
		width: calc(100% - 45px);
	}

	.AdminLayout .Header .Download {
		margin-top: -72px;
	}


}