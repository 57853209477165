.Login {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
    user-select: none;
}

.Login img {
	width: 200px;
	margin: 15px 0px;
}

.LoginWindow { 
	padding: 0px 15px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.Login input {
	background-color: #0000;
	outline: none;
	border: none;
	font-size: 16px;
	text-align: center;
	color: #fff;
	padding: 15px 15px;
}

.Login button {
	position: relative;
	transition: all 0.3s ease-in-out;
	border-radius: 15px;
	width: 100%;
	cursor: pointer;
	background-color: #0000;
	outline: none;
	border: none;
	font-size: 16px;
	text-align: center;
	color: #fff;
	padding: 15px 15px;
}

.Login button:hover {
    color: #ffcf25;
    background-color: rgb(255, 255, 255, 0.05);
}

