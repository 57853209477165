#root {
	overflow: hidden;
}

.App {
	width: 100vw;
	height: 100svh;
}

.Layout {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 400px auto;
}

.Chats {
	padding: 0px 10px;
	height: 100%;
	width: 100%;
	overflow: auto;
	touch-action: pan-y;
}

.Chats *, .ScrollContent * {
	touch-action: pan-y;
}

.Chats .FunctionalBar {
	position: relative;
	margin: 15px 5px;
	height: 24px;
	display: flex;
	justify-content: space-between;
}

 .MicroMenu button {
    width: 100%;
    position: relative;
	background-color: #0000;
	outline: none;
	border: 0;
	border-radius: 12.5px;
	color: #fff;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

 .MicroMenu  button:hover {
	color: #ffcf25;
	background-color: rgb(255, 255, 255, 0.05);
}

 .MicroMenu input {
	height: 35px;
	text-align: center;
	width: 100% !important;
}


 .MicroMenu {
	top: 30px;
	position: absolute;
	width: 100%;
	height: fit-content;
	padding: 10px;
	border-radius: 15px;
	background-color: #fff;
	z-index: 9999;
	background-color: rgb(0, 0, 0, 0.7);
	backdrop-filter: blur(5px);
}

 .MicroMenu::before {
	border-radius: 15px;
	top: 0;
	left: 0;
	position: absolute;
	content: " ";
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: rgb(255, 255, 255, 0.07);
}

 .MicroMenu {

}


.Chats .FunctionalBar svg {
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Chats .FunctionalBar svg:hover {
	color: #ffcf25;
}

.Chats .Chat {
	transition: background-color 0.3s;
	overflow: hidden;
	cursor: pointer;
	margin: 10px 0px;
	position: relative;
	display: flex;
	justify-content: left;
	gap: 10px;
	align-items: center;
	padding: 0px 10px;
	width: 100%;
	height: 65px;
	border-radius: 15px;
}

.Chats .Chat:hover {
	background-color: rgb(255, 255, 255, 0.05);
}

.Chats .Chat .User {
	width: 70%;
	display: flex;
	flex-direction: column;
}

.Chats .Chat .User .Name {
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
	font-weight: 600;
	font-size: 16px;
}

.Chats .Chat .User .Message {
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
	font-weight: 400;
	font-size: 12px;
}

.Avatar {
	padding-top: 2px;
	height: calc(100% - 20px);
	aspect-ratio: 1 / 1;
	background-color: #ffcf25;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-weight: 600;
	font-size: 18px;
	border-radius: 100%;
}

.Chats .Chat .Unread {
	padding-top: 2px;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 14px;
	background-color: #ffcf25;
	color: #000;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Chats .Chat .Date {
	position: absolute;
	right: 10px;
	bottom: 6.5px;
	font-weight: 500;
	font-size: 12px;
}

.Chats .Chat.Active {
	background-color: rgb(255, 255, 255, 0.07);
}
.MicroMenu input[type="text"] {
	background-color: #0000;
	border: 0;
	width: calc(100% - 65px);
	outline: none;
	color: #fff;
	margin: 0;
	font-size: 14px;
}

.Chats .FunctionalBar input {
	background-color: #0000;
	border: 0;
	width: calc(100% - 65px);
	outline: none;
	color: #fff;
	margin: 0;
	font-size: 14px;
}

.Window {
	padding: 10px;
	height: 100svh;
	width: 100%;
}

.Window .NoDialog {
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.Window .NoDialog .Logo {
	display: flex;
	align-items: end;
	gap: 10px;
}

.Window .NoDialog .Logo img {
	width: 200px;
}

.Window .NoDialog .Logo p {
	margin-bottom: 3px;
	border-radius: 255px;
	background-color: #ffcf25;
	color: #000;
	font-size: 12px;

	height: fit-content;
	padding: 3px 10px;
	padding-top: 5px;
}

.Loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: spin 3s ease-in-out infinite;
}

.Loader svg {
	fill: #ffcf25;
}

@keyframes spin {
	0% {
		transform: rotate(0deg) scale(0.9);
	}
	50% {
		transform: rotate(180deg) scale(1.1);
	}
	100% {
		transform: rotate(360deg) scale(0.9);
	}
}

.Background {
	overflow: hidden;
	top: 0px;
	z-index: -1;
	left: 0px;
	position: fixed;
	width: 100svw;
	height: 100vh;
}

.Background .Ellipse1 {
	position: absolute;
	top: 126px;
	left: 0px;
	width: 301px;
	border-radius: 301px;
	background: #bebebe90;
	pointer-events: none;
	height: 154px;
	filter: blur(175px);
}

.Background .Ellipse2 {
	position: absolute;
	bottom: 132px;
	right: 0px;
	width: 330px;
	border-radius: 330px;
	pointer-events: none;
	background: #ffd02591;
	height: 154px;
	filter: blur(200px);
}

.Messages {
	width: 100%;
	height: 100%;
	position: relative;
}

.Messages .Header {
	gap: 10px;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-weight: 500;
	font-size: 18px;
}

.Messages .Footer {
	transition: all 0.3s ease-in-out;
	width: 100%;
	padding-bottom: 15px;
	position: fixed;
	bottom: 0;
}

.Messages .Footer .Container textarea {
	touch-action: pan-y;
}

.Messages .Footer .Container {
	border-radius: 15px;
	backdrop-filter: blur(5px);
	background-color: rgb(255, 255, 255, 0.07);
	padding: 10px 15px;
	width: calc(100% - 420px);
	position: relative;
	display: flex;
	gap: 15px;
	align-items: end;
	justify-content: space-between;
}

.Messages .Footer textarea {
	z-index: 999;
	height: auto;
	font-size: 16px;
	resize: none;
	padding-top: 2px;
	min-height: 24px;
	width: calc(100% - 80px);
	background-color: #0000;
	outline: none;
	border: none;
	color: #fff;
}

.Messages .Footer svg {
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Messages .Footer svg:hover {
	cursor: pointer;
	color: #ffcf25;
}

.Messages .ScrollableList * {
	touch-action: pan-y;
}

.Messages .ScrollableList {
	touch-action: pan-y;
	overflow-y: auto;
	height: calc(100% - 125px);
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	gap: 10px;
}

.Messages .ScrollableList .Msg {
	position: relative;
	min-width: 100px;
	max-width: calc(50% - 10px);
	word-break: break-word;
	white-space: pre-wrap;
	line-break: auto;
	width: fit-content;
	padding: 7px 15px;
	border-radius: 15px;
	font-size: 16px;
	background-color: rgb(255, 255, 255, 0.07);
}

.Messages .ScrollableList .Msg.Active {
	animation: pulse 5 1s ease-in-out;
}

.Messages .ScrollableList .Msg.Active::after {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	content: " ";
	width: calc(100% - 30px);
	height: 1px;
	background: linear-gradient(90deg, #ffcf2500, #ffcf25, #ffcf2500);
	animation: disappear 5s ease-in 1;
	animation-fill-mode: forwards;
}

@keyframes pulse {
	0% {
		background-color: rgb(255, 255, 255, 0.07);
	}
	50% {
		background-color: rgb(255, 255, 255, 0.1);
	}
	100% {
		background-color: rgb(255, 255, 255, 0.07);
	}
}

.Messages .ScrollableList .NewDay {
	background-color: rgb(255, 255, 255, 0.07);
	padding: 7px 15px;
	border-radius: 15px;
	width: fit-content;
	align-self: center;
}

.Messages .ScrollableList .Msg.Our {
	align-self: flex-end;
	margin-right: 10px;
}

.Messages .ScrollableList .Msg .Clickable {
	color: hsl(47, 100%, 87%);
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.Messages .ScrollableList .Msg .Clickable:hover {
	color: hsl(47, 100%, 57%);
}

.Messages .ScrollableList .Msg.Our .Username {
	font-size: 12px;
	text-align: left;
}

.Messages .ScrollableList .Msg .Date {
	font-size: 12px;
	text-align: right;
}

.Messages .ScrollableList .Msg .Attachments {
	width: 100%;
	padding: 10px 0px;
}

.Messages .ScrollableList .Msg .Attachments img {
	border-radius: 7.5px;
	width: 100%;
	height: auto;
	max-height: 400px;
	object-fit: contain;
}

.Messages .ScrollableList .Msg .Attachments video {
	border-radius: 7.5px;
	width: 100%;
	height: auto;
	max-height: 400px;
	object-fit: contain;
}

.Messages .ScrollableList .Msg .Attachments a {
	color: #ffcf25;
	text-decoration: none;
}

.Messages .ScrollableList .Msg .Attachments audio {
	min-width: 250px;
}

.PhotoView-Slider__Counter {
	opacity: 0 !important;
}

.NotificationContainer {
	user-select: none;
	position: absolute;
	right: 15px;
	top: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.NotificationContainer .Notification {
	min-width: 150px;
	backdrop-filter: blur(15px);
	background-color: rgb(255, 255, 255, 0.07);
	padding: 7px 15px;
	padding-left: 7px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 7px;
	position: relative;
	z-index: 999999;
}

.NotificationContainer .Notification::after {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	content: " ";
	width: calc(100% - 30px);
	height: 1px;
	background: linear-gradient(90deg, #ffcf2500, #ffcf25, #ffcf2500);
	animation: disappear 5s ease-in 1;
	animation-fill-mode: forwards;
}

@keyframes disappear {
	from {
		width: calc(100% - 30px);
		opacity: 1;
	}
	to {
		width: 0px;
		opacity: 0;
	}
}

.NotificationContainer .Notification svg {
	fill: #ffcf25;
}

.NotificationContainer .Notification p {
	margin-top: 1px;
}

.e404 {
	width: 100svw;
	height: 100svh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
}

.Layout .Window .Messages .Header .Back {
	position: absolute;
	left: 10px;
	display: none;
}

.Layout .Window .Messages .Header .Right {
	position: absolute;
	right: 10px;
}

.Layout .Window .Messages .Header svg {
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Layout .Window .Messages .Header svg:hover {
	color: #ffcf25;
}

.Layout .Window .Messages .Header .Hidden {
	display: none;
}

@media screen and (max-width: 850px) {
	.Layout {
		display: block;
		height: 100svh;
		overflow: hidden;
	}

	.Layout .Chats.Hidden {
		opacity: 0;
		height: 0px;
	}

	.Layout .Window.Hidden {
		opacity: 0;
		height: 0px;
	}

	.Layout .Window .Messages .Footer .Container {
		width: calc(100% - 20px);
	}

	.Layout .Window .Messages .Header .Back {
		display: block;
	}

	.Layout .Window .Messages .Header .Hidden {
		display: block;
	}

	.Messages .ScrollableList .Msg {
		max-width: calc(65% - 10px);
	}

	.Info {
		width: 100vw;
		text-align: center;
	}
}


