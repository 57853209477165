

* {
	font-family: "TT Firs Neue";
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	-ms-touch-action: manipulation;
	touch-action: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  transition: background-color 0.3s;
  margin: 0;
  font-family: "TT Firs Neue"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

h1 {
  font-weight: 500;
}


::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: #0000;
}

::-webkit-scrollbar-thumb {
  border-radius: 255px;
	width: 5px;
	background-color: #ffcf25
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}