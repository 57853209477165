.Info {
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    gap: 25px;
}

.Info img {
    width: 350px;
}

.Info audio {
    outline: none;
}

.Info button {
    width: 70%;
    position: relative;
	background-color: #0000;
	outline: none;
	border: 0;
	border-radius: 12.5px;
	color: #fff;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.Info button:hover {
	color: #ffcf25;
	background-color: rgb(255, 255, 255, 0.05);
}
