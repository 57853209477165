.Template {
    overflow: hidden;
    width: 300px;
    height: 300px;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    background-color: rgb(0, 0, 0, 0.8 );
    position: fixed;
    z-index: 999;
    right: 10px;
    padding: 10px;
    bottom: 70px;
}

.Template::before {
    top: 0;
    left: 0;
    position: absolute;
    content: " ";
    width: 300px;
    height: 300px;
    z-index: -1;
	background-color: rgb(255, 255, 255, 0.07);
}


.Template .Ellipse1 {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 154px;
	border-radius: 330px;
	pointer-events: none;
	background: #ffd0252a;
	height: 154px;
	filter: blur(100px);
}


.Template .TemplatesList {
    touch-action: pan-y !important;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
}

.Template .TemplatesList .TemplateItem {
    text-overflow: ellipsis;
    word-wrap: break-word;
    touch-action: pan-y !important;
    border-radius: 7.5px;
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
    transition: all .3s ease-in-out;
}
.Template .TemplatesList .TemplateItem p {
    touch-action: pan-y;
}

.Template .TemplatesList .TemplateItem:hover {
    background-color: rgb(255, 255, 255, 0.07);
}